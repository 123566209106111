import { FunctionComponent, useState, useEffect, useRef, ChangeEvent } from 'react';
import styled from 'styled-components';

import { v4 as uuidv4 } from 'uuid';
import { StripeElements, StripeElementsOptionsMode, StripeError } from '@stripe/stripe-js';

import { useApi } from '../context/ApiProvider';
import { AddressCountries, AddressRegions, CourierTypes, DeliveryMethod, OrderPaymentAuthenticationRequest, OrderTypes, OrdersPaymentAuthentication200Response, CreateOrderRequest, SparrowHubApiInterface, User, CreateOrderRequestItemsInner, CartItem, CartWithItems, PolicyFeature } from 'sparrowhub-client-axios';
import { CustomerDetails, formatPriceFromFloat, formatPriceToInt, getBasketGST } from '../store/cartSlice';

import { InputField } from './InputField';
import { Checkbox } from './Checkbox';
import { Button, ButtonType } from './Button';
import { Modal } from './Modal';
import { Alert, AlertIcon, AlertType } from './Alert';
import { AddressInput } from './AddressInput';
import { SimpleSpinner } from './SimpleSpinner';

const closeIcon = `${process.env.REACT_APP_ASSET_BASE_PATH}/scripts/images/icon-close.svg`;
const tyroHealthLogo = `${process.env.REACT_APP_ASSET_BASE_PATH}/logos/logo-tyro-health.png`;

type PaymentInputProps = {
  heading?: string
  cart: CartWithItems
  orderItems: Array<any>
  deliveryMethod: DeliveryMethod | undefined
  deliveryAddress: CustomerDetails
  billingAddress: CustomerDetails
  onSetDeliveryAddress: Function
  onSetBillingAddress: Function
  onSuccess: Function
}


export const PaymentInput: FunctionComponent<PaymentInputProps> = ({
  heading,
  cart,
  orderItems,
  deliveryMethod,
  deliveryAddress,
  billingAddress,
  onSetDeliveryAddress,
  onSetBillingAddress,
  onSuccess
}) => {
  const api = useApi();

  // common state
  const [errorMessage, setErrorMessage] = useState('');
  const [billingSame, setBillingSame] = useState(true);
  const [showLoader, setShowLoader] = useState(false);

  // vanilla card input state
  const [cardNumber, setCardNumber] = useState('');
  const [cardName, setCardName] = useState('');
  const [expiryMonth, setExpiryMonth] = useState('');
  const [expiryYear, setExpiryYear] = useState('');
  const [cvv, setCvv] = useState('');

  // Tyro 3DS state
  const [paymentAuthenticated, setPaymentAuthenticated] = useState(false);
  const [paymentInProgress, setPaymentInProgress] = useState(false);
  const [authenticationInProgress, setAuthenticationInProgress] = useState(false);
  const [authenticationHtml, setAuthenticationHtml] = useState('');
  const [apiResult, setApiResult] = useState<any>(null);
  const [show3DSModal, setShow3DSModal] = useState(false);
  const [remove3dsEventListener, setRemove3dsEventListener] = useState(false);

  // state variables managed by useRef to maintain reactivity within event listener
  const [references, _setReferences] = useState({
    order_ref: '',
    transaction_ref: '',
    provider_ref: ''
  })
  const referencesRef = useRef(references);
  const setReferences = (payload: any) => {
    referencesRef.current = payload;
    _setReferences(payload);
  }
  
  const [paymentElementIsInvalid, _setPaymentElementIsInvalid] = useState(true);
  const paymentElementIsInvalidRef = useRef(paymentElementIsInvalid);
  const setPaymentElementIsInvalid = (payload: any) => {
    paymentElementIsInvalidRef.current = payload;
    _setPaymentElementIsInvalid(payload);
  }

  const [authFlow, _setAuthFlow] = useState('');
  const authFlowRef = useRef(authFlow);
  const setAuthFlow = (value: 'frictionless' | 'challenge' | 'no_html') => {
    authFlowRef.current = value;
    _setAuthFlow(value);
  }
  
  const [authSuccess, _setAuthSuccess] = useState(false);
  const authSuccessRef = useRef(authSuccess);
  const setAuthSuccess = (value: boolean) => {
    authSuccessRef.current = value;
    _setAuthSuccess(value);
  }

  // computed
  const useStripePayment = (): boolean => {
    return false;
  }
  
  // methods
  const getBasketSubtotal = (): number => {
    let subtotal = 0;
    orderItems.forEach((item: any) => {
      subtotal += item.price * item.qty;
    })
    return subtotal;
  }

  const basketTotal = (): number => {
    let total = getBasketSubtotal();
    if (cart!.requires_delivery) {
      total += parseFloat(deliveryMethod!.total as unknown as string);
    }
    return total;
  }

  const paymentIsInvalid = (): boolean => {
    const formEl = (document.getElementById('form_payment-details') as HTMLFormElement);

    if (useStripePayment()) {
      return (
        paymentElementIsInvalidRef.current ||
        billingAddress.first_name === '' ||
        billingAddress.last_name === '' ||
        billingAddress.email === '' ||
        billingAddress.phone === '' ||
        billingAddress.street1 === '' ||
        billingAddress.city === '' ||
        billingAddress.state_code === '' ||
        billingAddress.postcode === '' || billingAddress.postcode.length !== 4 ||
        (formEl && !formEl.checkValidity())
      )
    } else {
      return (
        cardNumber === '' ||
        expiryMonth === '' ||
        expiryYear === '' || expiryYear.length !== 2 ||
        cvv === '' ||
        cardName === '' ||
        billingAddress.first_name === '' ||
        billingAddress.last_name === '' ||
        billingAddress.email === '' ||
        billingAddress.phone === '' ||
        billingAddress.street1 === '' ||
        billingAddress.city === '' ||
        billingAddress.state_code === '' ||
        billingAddress.postcode === '' || billingAddress.postcode.length !== 4 ||
        (formEl && !formEl.checkValidity())
      )
    }
  }
  
  const billingData = (): any => {
    return {
      first_name: billingAddress.first_name,
      last_name: billingAddress.last_name,
      email: billingAddress.email,
      phone: billingAddress.phone,
      address: {
        street: billingAddress.street2 !== '' ? JSON.stringify([billingAddress.street1, billingAddress.street2]) : JSON.stringify([billingAddress.street1]),
        city: billingAddress.city,
        state_code: billingAddress.state_code as AddressRegions,
        postcode: billingAddress.postcode,
        country_code: AddressCountries.Au
      }
    }
  }
  
  const billingDataStripe = (): any => {
    return {
      name: `${billingAddress.first_name} ${billingAddress.last_name}`,
      email: billingAddress.email,
      phone: billingAddress.phone,
      address: {
        line1: JSON.stringify([billingAddress.street1]),
        line2: JSON.stringify([billingAddress.street2]),
        city: billingAddress.city,
        state: billingAddress.state_code as AddressRegions,
        postal_code: billingAddress.postcode,
        country: AddressCountries.Au
      }
    }
  }

  const deliveryData = (): any => {
    return {
      first_name: deliveryAddress.first_name,
      last_name: deliveryAddress.last_name,
      email: deliveryAddress.email,
      phone: deliveryAddress.phone,
      address: {
        street: deliveryAddress.street2 !== '' ? JSON.stringify([deliveryAddress.street1, deliveryAddress.street2]) : JSON.stringify([deliveryAddress.street1]),
        city: deliveryAddress.city,
        state_code: deliveryAddress.state_code as AddressRegions,
        postcode: deliveryAddress.postcode,
        country_code: AddressCountries.Au,
      }
    }
  }

  // const authData = (): any => {
  //   return {
  //     order_ref: billing.order_ref,
  //     transaction_ref: billing.transaction_ref,
  //     provider_ref: billing.provider_ref
  //   }
  // }

  const paymentData = (): any => {
    const cardFields = useStripePayment()
      // send dummy data for Stripe payment
      ? {
          card_name: "SparrowHub",
          card_number: "4242424242424242",
          card_expiry_month: 1,
          card_expiry_year: 29,
          card_cvc: "100",
        }
      // send collected data for Tyro payment
      : {
          card_name: cardName,
          card_number: cardNumber.replace(/\s/g,''),
          card_expiry_month: parseInt(expiryMonth),
          card_expiry_year: parseInt(expiryYear),
          card_cvc: cvv
        }
    
    return {
      total: formatPriceFromFloat(basketTotal()),
      tax: formatPriceFromFloat(getBasketGST(orderItems, deliveryMethod)),
      payment_method_code: "card",
      ...cardFields
    }
  }

  const itemsData = (): Array<CreateOrderRequestItemsInner> => {
    return orderItems.map((item: CartItem) => {
      return {
        sku: item.sku,
        name: item.name,
        qty: item.qty,
        price: parseFloat(item.price!),
        tax: item.tax === undefined ? 0 : parseFloat(item.tax),
        is_prescription: false
      }
    })
  }

  // methods
  const initStripeElements = (): StripeElements | null => {
    const options: StripeElementsOptionsMode = {
      mode: 'payment',
      amount: formatPriceToInt(basketTotal()),
      currency: 'aud',
      paymentMethodCreation: 'manual',
      // appearance: {}
    };

    if (window.stripe === null || window.stripe === undefined) {
      console.warn('Unable to init Stripe Elements, Stripe is null.');
      return null;
    } else {
      // Set up Stripe.js and Elements to use in checkout form
      const elements = window.stripe.elements(options);
      
      // Create the Payment Element
      const paymentElement = elements.create('payment', {
        fields: {
          billingDetails: 'never'
        }
      });

      // Watch element for validity
      paymentElement.on('change', (event) => {
        if (event.complete) {
          setPaymentElementIsInvalid(false);
        } else {
          setPaymentElementIsInvalid(true);
        }
      })

      // Mount element 
      paymentElement.mount('#stripe-payment-element');

      // Return elements object
      return elements;
    }
  }

  const handlePayNow = (): void => {
    if (useStripePayment()) {
      handleStripePayment();
    } else {
      authenticatePaymentMethod();
    }
  }

  const handleStripePayment = async (): Promise<void> => {
    setPaymentInProgress(true);
    setErrorMessage('');

    if (window.stripe === null || window.stripeElements === null) {
      console.warn('Unable to init Stripe Elements, Stripe or Elements are not initialised.');
    } else {
      // submit elements
      const elementsResult = await window.stripeElements.submit();
      if (elementsResult.error) {
        handleStripeError(elementsResult.error);
        return
      }

      // create payment method
      const createPaymentMethodResult = await window.stripe.createPaymentMethod({
        elements: window.stripeElements,
        params: {
          billing_details: billingDataStripe()
        },
      });
      if (createPaymentMethodResult.error) {
        handleStripeError(createPaymentMethodResult.error);
        return
      }

      const paymentMethod = createPaymentMethodResult.paymentMethod;

      // set references
      const uuid = uuidv4();
      setReferences({
        transaction_ref: uuid,
        order_ref: uuid,
        provider_ref: paymentMethod.id
      });

      // handle process new order
      handleCompleteProcess();
    }
  }

  const handleStripeError = (error: StripeError): void => {
    setPaymentInProgress(false);
    setErrorMessage(error.message || 'An unexpected error has occurred while processing your payment. Please try again.');
  }

  const handleShow3dsModal = async (): Promise<void> => {
    // submit injected Tyro setup form
    const script = document.getElementById('authenticate-payer-script');
    if (script) {
      // clear error message
      setErrorMessage('');

      const frictionlessFormId = 'threedsFrictionLessRedirectForm';
      const scriptText = (script as any).text;

      if (scriptText.includes(frictionlessFormId)) {
        // frictionless flow: skip modal and extract results from form element
        setAuthFlow('frictionless');
        const formEl = document.getElementById(frictionlessFormId);
        const gatewayRecommendation = (document.getElementsByName('response.gatewayRecommendation')[0] as HTMLInputElement).value;
        
        // proceed based on gatewayRecommendation as normal
        if (formEl && gatewayRecommendation === 'PROCEED') {
          handle3dsSuccess();
        } else {
          handle3dsFailure();
        }
      } else {
        // challenge flow: trigger script to show 3DS challenge
        setAuthFlow('challenge');
        eval(scriptText);

        // attach listener for response event
        window.addEventListener('message', handle3dsResponse, false);

        // show modal
        setTimeout(() => {
          setShow3DSModal(true);
        }, 300);
      }
    }
  }

  const handleReset3dsModal = (): void => {
    setShow3DSModal(false);
    setPaymentInProgress(false);
    setAuthenticationHtml('');
  }

  const allowed3dsOrigins: Array<string> = [
    'https://ap.gateway.mastercard.com',
    'https://mtf.gateway.mastercard.com'
  ];

  const handle3dsResponse = (event: MessageEvent): void => {
    if (!allowed3dsOrigins.includes(event.origin)) { return; }  

    const data = JSON.parse(event.data);

    // Check the result
    if ((data.result === 'SUCCESS') && !remove3dsEventListener) {
      // Remove the event listener and finalise the order if result is from the first event
      setRemove3dsEventListener(true);
      window.removeEventListener('message', handle3dsResponse, false);
      
      handle3dsSuccess();
    } else if (data.result === 'SUCCESS') {
      // If the success is from a subsequent event, don't run `handleReset3dsModal` to prevent a race condition
      setTimeout(() => {
        handleReset3dsModal();
      }, 300);
    } else {
      handle3dsFailure();
    }
  }

  const handle3dsSuccess = (): void => {
    setAuthSuccess(true);
    trackAuthenticatePayment();

    handleCompleteProcess();
    
    setTimeout(() => {
      handleReset3dsModal();
    }, 300);
  }
  
  const handle3dsFailure = (): void => {
    setAuthSuccess(false);
    trackAuthenticatePayment();

    handleReset3dsModal();
    setPaymentInProgress(false);
    setErrorMessage('We were unable to validate your payment method. Please try again with a different payment method.');
    setTimeout(() => {
      window.scrollTo({ top: 9999, left: 0, behavior: 'smooth' });
    }, 300);
  }

  const trackAuthenticatePayment = (): void => {
    // gtag('event', 'authenticate_payment', {
    //   'authentication_flow': authFlowRef.current,
    //   'authentication_success': authSuccessRef.current,
    //   'transaction_reference': referencesRef.current.transaction_ref
    // });
  }

  const authenticatePaymentMethod = async (): Promise<void> => {
    // if all necessary fields not provided, return early
    if (paymentIsInvalid()) {
      return
    }

    // set state
    setPaymentInProgress(true);
    setErrorMessage('');
    setAuthenticationInProgress(true);

    // generate reCAPTCHA token
    await grecaptcha.enterprise.ready(async () => {
      const recaptchaToken = await grecaptcha.enterprise.execute(process.env.REACT_APP_RECAPTCHA_KEY, {action: 'checkout'});

      const requestBody: OrderPaymentAuthenticationRequest = {
        security_token: recaptchaToken,
        partner_id: cart.partner_id,
        browser: {
          challenge_window_size: document.documentElement.clientWidth > 600 ? '390_X_400' : '250_X_400',
          user_agent: window.navigator.userAgent,
          language: window.navigator.language,
          timezone: new Date().getTimezoneOffset().toString(),
          screen_color_depth: window.screen.colorDepth,
          screen_width: window.screen.width,
          screen_height: window.screen.height,
          is_java_enabled: false,
          is_javascript_enabled: true, 
        },
        billing: billingData(),
        delivery: deliveryData(),
        payment: paymentData(),
        from_feature: PolicyFeature.Carts
      }

      api.ordersPaymentAuthentication(requestBody).then((response) => {
        setAuthenticationInProgress(false);
        const typedResponse: OrdersPaymentAuthentication200Response = { data: JSON.parse((response.data as any).data) };

        if (typedResponse.data) {
          setPaymentAuthenticated(typedResponse.data.proceed_with_payment);

          if (typedResponse.data.proceed_with_payment) {
            setReferences({
              transaction_ref: typedResponse.data.transaction_reference,
              order_ref: typedResponse.data.order_reference,
              provider_ref: typedResponse.data.provider_reference || ''
            });

            if (typedResponse.data.payment_authentication_html) {
              // if authentication URL provided, show modal
              setAuthenticationHtml(typedResponse.data.payment_authentication_html);
              setTimeout(() => {
                handleShow3dsModal();
              }, 300);
            } else {
              // else no html flow (proceed as if frictionless)
              setAuthFlow('no_html');
              handle3dsSuccess();
            }
          } else {
            setErrorMessage('We were unable to validate your payment method. Please try again with a different payment method.');
            setPaymentInProgress(false);
            setTimeout(() => {
              window.scrollTo({ top: 9999, left: 0, behavior: 'smooth' });
            }, 300);
          }
        }

      })
      .catch(error => {
        console.error(error);
        setPaymentInProgress(false);
        setErrorMessage('We were unable to validate your payment method. Please try again.');
        setTimeout(() => {
          window.scrollTo({ top: 9999, left: 0, behavior: 'smooth' });
        }, 300);
        setAuthenticationInProgress(false);
      })
    });
  }

  const handleCompleteProcess = async (): Promise<void> => {
    // manually submit GA events
    // gtag('event', 'form_submit', {
    //   'form_id': 'form_checkout-page_payment-details',
    //   'form_name': 'Payment Information',
    //   'form_submit_text': 'Pay Now'
    // });

    // gtag('event', 'add_payment_info', {
    //   'currency': 'AUD',
    //   'value': getBasketSubtotal(scripts),
    //   'items': formatItemsArray(scripts)
    // });

    // gtag('event', 'purchase', {
    //   'currency': 'AUD',
    //   'transaction_id': referencesRef.current.order_ref,
    //   'value': getBasketSubtotal(scripts),
    //   'items': formatItemsArray(scripts)
    // });

    // set state
    // setShowPage(false);
    setShowLoader(true);

    // generate reCAPTCHA token
    await grecaptcha.enterprise.ready(async () => {
      const token = await grecaptcha.enterprise.execute(process.env.REACT_APP_RECAPTCHA_KEY, {action: 'checkout'});
      handleProcessNewOrder(token);
    });
  }

  const handleProcessNewOrder = (recaptchaToken: string): void => {
    const requestBody: Partial<CreateOrderRequest> = {
      order_type_code: OrderTypes.Sale,
      security_token: recaptchaToken,
      partner: {
        id: cart.partner_id,
        location_code: cart.location.code
      },
      customer: {
        first_name: deliveryAddress.first_name,
        last_name: deliveryAddress.last_name,
        email: deliveryAddress.email,
        phone: deliveryAddress.phone,
      },
      payment: {
        auth: referencesRef.current,
        ...paymentData()
      },
      items: itemsData(),
      from_cart_id: cart.id
    };

    if (cart.requires_delivery) {
      requestBody.delivery = {
        courier_type_code: deliveryMethod!.courier_type_code as CourierTypes,
        delivery_type_code: deliveryMethod!.delivery_type_code,
        total: formatPriceFromFloat(parseFloat(deliveryMethod!.total as unknown as string)),
        tax: formatPriceFromFloat(parseFloat(deliveryMethod!.tax as unknown as string)),
        ...deliveryData() as any,
      };
      requestBody.billing = billingData();
    } else {
      if (billingSame) {
        requestBody.billing = billingData();
      } else {
        requestBody.delivery = {...deliveryData()};
        requestBody.billing = billingData();
      }
    }

    api.createOrder((requestBody as CreateOrderRequest)).then((response) => {
      setAuthenticationInProgress(false);
      setApiResult(response);
      onSuccess();
    })
    .catch(error => {
      setAuthenticationInProgress(false);
      handleError(error.response);
    })
  }

  const handleError = (apiResponse: any): void => {
    // setShowPage(false);
    setShowLoader(false);
    
    // setTimeout(() => {
    //   navigate('/checkout-error');
    // }, 300);

    if (apiResponse && apiResponse.data && apiResponse.data.message) {
      setErrorMessage(apiResponse.data.message);
    } else {
      setErrorMessage('An error occurred while finalising your payment. Please try again.');
    }
  }

  const formatter = new Intl.NumberFormat('en-AU', {
    style: 'currency',
    currency: 'AUD'
  });
  const formatPrice = (price: number | string): string => {
    let priceNum = typeof price === 'number'
      ? price
      : parseFloat(price);
    return formatter.format(priceNum);
  }

  // handle billing address
  useEffect(() => {
    if (billingSame) {
      onSetBillingAddress({
        first_name: deliveryAddress.first_name,
        last_name: deliveryAddress.last_name,
        email: deliveryAddress.email,
        phone: deliveryAddress.phone,
        street1: deliveryAddress.street1,
        street2: deliveryAddress.street2,
        city: deliveryAddress.city,
        state_code: deliveryAddress.state_code,
        postcode: deliveryAddress.postcode
      })
    } else {
      onSetBillingAddress({
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        street1: '',
        street2: '',
        city: '',
        state_code: '',
        postcode: ''
      })
    }
  }, [ billingSame ])

  // init page
  useEffect(() => {
    setTimeout(() => {
      // if (config.has_landed) {
      //   gtag('event', 'begin_checkout', {
      //     'currency': 'AUD',
      //     'value': getBasketSubtotal(scripts),
      //     'items': formatItemsArray(scripts)
      //   });
      // }

      if (useStripePayment()) {
        window.stripeElements = initStripeElements();
      }
    }, 10);
  }, []);
  
  return (
    <StyledPaymentInput className="PaymentInput">
      {/* Payment input fields */}
      <div>
        {heading && <h3>{heading}</h3>}
        <form name="Payment Information" id="form_payment-details">
          {useStripePayment() ?
            // Stripe elements input
            <div id="stripe-payment-element"></div>
          :
            // Vanilla input for Tyro
            <>
              <InputField type="text" label="Card Number" name="cardnumber" autocomplete="cc-number" regex={/^\d+$/} value={cardNumber} onChange={(e: ChangeEvent) => { setCardNumber((e.target as HTMLInputElement).value); setPaymentAuthenticated(false) }} required />
              <div className="Payment_row">
                <InputField type="text" label="Expiry" name="ccmonth" autocomplete="cc-exp-month" regex={/^\d{0,2}$/} placeholder="MM" value={expiryMonth} onChange={(e: ChangeEvent) => { setExpiryMonth((e.target as HTMLInputElement).value); setPaymentAuthenticated(false) }} required />
                <InputField type="text" label="Year" name="ccyear" autocomplete="cc-exp-year" regex={/^\d{0,2}$/} placeholder="YY" value={expiryYear} onChange={(e: ChangeEvent) => { setExpiryYear((e.target as HTMLInputElement).value); setPaymentAuthenticated(false) }} required />
                <InputField type="text" label="CVC" name="cvc" autocomplete="cc-csc" regex={/^\d{0,4}$/} value={cvv} onChange={(e: ChangeEvent) => { setCvv((e.target as HTMLInputElement).value); setPaymentAuthenticated(false) }} required />
              </div>
              <InputField type="text" label="Name as it appears on card" name="ccname" autocomplete="cc-name" value={cardName} onChange={(e: ChangeEvent) => { setCardName((e.target as HTMLInputElement).value); setPaymentAuthenticated(false) }} required />
            </>
          }

          <Checkbox id="billingSame" title="Billing details are the same as delivery details" selected={billingSame} onChange={() => setBillingSame(billing => !billing)}>
            <>Billing details are the same as {cart!.requires_delivery ? 'delivery' : 'contact'} details</>
          </Checkbox>

          {!billingSame &&
            <div className="PaymentInput_billingAddress">
              <AddressInput heading="Billing Details" address={billingAddress} onSetAddress={onSetBillingAddress} />
            </div>
          }
        </form>
      </div>

      <div className="PaymentInput_container">
        {/* Error message */}
        {errorMessage &&
          <div style={{ margin: '40px 0 0px 0' }}>
            <Alert type={AlertType.Urgent} icon={AlertIcon.ExclamationRed}>
              <p>{errorMessage}</p>
            </Alert>
          </div>
        }

        {/* Primary button */}
        <Button text="Pay Now" type={ButtonType.Primary} disabled={paymentIsInvalid() || authenticationInProgress || paymentInProgress || showLoader} loading={authenticationInProgress || paymentInProgress || showLoader} onClick={handlePayNow} />
        <img src={tyroHealthLogo} alt="Secured by Tyro Health" title="Secured by Tyro Health" />
      </div>

      {/* 3DS Modal */}
      <div className={`PaymentInput_modalParent elementTransition ${!show3DSModal && 'hidden'}`}>
        <Modal show={true}>
          <img className="Modal_close button" src={closeIcon} onClick={handleReset3dsModal} />
          <div dangerouslySetInnerHTML={{__html: authenticationHtml}}></div>
        </Modal>
      </div>

      {/* Loader */}
      <SimpleSpinner show={showLoader || authenticationInProgress || paymentInProgress} />
    </StyledPaymentInput>
  );
}

const StyledPaymentInput = styled.div`
  h3 {
    font-size: 1.125rem !important; // 18px
    margin: 0;
  }

  .Payment_row {
    display: grid;
    grid-template-columns: 1fr 1fr 2fr;
    gap: 10px;
  
    &:not(:first-child) {
      margin-top: -10px;
    }

    > :nth-child(2) {
      label {
        opacity: 0;
        pointer-events: none;
      }
    }
  }

  .PaymentInput_billingAddress {
    margin-top: 30px;
  }
  
  .PaymentInput_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    div {
      width: 100%;
    }

    .Button {
      margin-top: 40px;
    }

    img {
      width: 153px;
      height: auto;
    }
  }
`